import { E_VIEW_MODE } from '@/components/editor/ThinkEditor.Defined';
export const DemoCaseForbidPasteFromOuter = {
  methods: {
    async DoDemoCaseForbidPasteFromOuter() {
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;

      var docName = '禁止粘贴外部内容';
      let thinkEditor = currentEditorsInstance.GetEditor(docName);

      if (thinkEditor === undefined) {
        thinkEditor = await editorsTabsView.CreateDocEditor(docName);

        var url = '/system/emr/演示/00-演示病例/AdmissionRecord.xml';
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();

        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);

        thinkEditor.ParseDocs(docName, '', xml, {});

        thinkEditor.SetUserProfile({ terminal: 'pc', id: 'appGuid1', name: '主治医生1', level: 1, claims: '主治医生' });

        /*************************************************
         * 配置 禁止粘贴外部内容
         * **********************************************/
        thinkEditor.SetDocConfig('', { forbidPasteFromOuter: true });

        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});
      }
      //UI 中显示该文档
      editorsTabsView.SelectDocEditor(docName);
    },
    async OnAllowPasteFromOuterTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.SetDocConfig('', { forbidPasteFromOuter: false });
    },
    async OnForbidPasteFromOuterTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.SetDocConfig('', { forbidPasteFromOuter: true });
    }
  }
};
