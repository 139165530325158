import { render, staticRenderFns } from "./EditorHeader.vue?vue&type=template&id=74f5a10e&scoped=true"
import script from "./EditorHeader.vue?vue&type=script&lang=js"
export * from "./EditorHeader.vue?vue&type=script&lang=js"
import style0 from "./EditorHeader.vue?vue&type=style&index=0&id=74f5a10e&prod&lang=css"
import style1 from "./EditorHeader.vue?vue&type=style&index=1&id=74f5a10e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74f5a10e",
  null
  
)

export default component.exports